import React from "react"

const Logo = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="499.6px"
      height="499.6px"
      viewBox="0 0 499.6 499.6"
      fill="currentColor"
    >
      <path d="M249.8,0C111.8,0,0,111.8,0,249.8s111.8,249.8,249.8,249.8s249.8-111.8,249.8-249.8S387.7,0,249.8,0z M240.4,401.7H184 V209.5h22.4c28.2,0,34,4.3,34,27.9C240.4,268.9,240.4,401.7,240.4,401.7z M338.7,401.7h-56.4V209.5h22.4c28.2,0,34,4.3,34,27.9 C338.7,268.9,338.7,401.7,338.7,401.7z M386.4,401V279.8c0-111.6,6.5-124.9-58.6-124.9h-188V401H83.5V98h130.1 C452.2,98,445,99.2,445,252C445,362.9,419,388.3,386.4,401z" />
    </svg>
  )
}
export default Logo
